import React from "react";
import PropTypes from "prop-types";
import commaNumber from "comma-number";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

import { DataFormatting } from "../../../../../utils/helpers";

const CellSparkline = ({
  data,
  dtKey,
  valueKey,
  seriesName,
  isDarkTheme,
  midnightPlots,
  weekendBands,
  dateTimeSeries,
  width
}) => {
  let seriesData = [];
  let minValue;
  let maxValue;
  if (dateTimeSeries) {
    for (let i = 0, j = 0; i < dateTimeSeries.length; i++) {
      const dtRaw = dateTimeSeries[i];
      const dt = DataFormatting.formatDateTime(dtRaw);
      const s = data[j];
      let match = false;
      if (s) {
        const sDtRaw = s[dtKey];
        const sValueRaw = s[valueKey];
        const sDt = DataFormatting.formatDateTime(sDtRaw);
        if (dt === sDt) {
          const sValue = DataFormatting.formatValue(valueKey, sValueRaw);
          seriesData.push([sDt, sValue]);
          match = true;
          j++;

          if (sValueRaw < minValue || minValue === undefined)
            minValue = sValueRaw;

          if (sValueRaw > maxValue || maxValue === undefined)
            maxValue = sValueRaw;
        }
      }
      if (!match) {
        seriesData.push([dt, null]);
      }
    }
  }

  const seriesColor = DataFormatting.getSeriesColor(
    isDarkTheme,
    valueKey,
    minValue,
    maxValue
  );

  const tooltipFormats = DataFormatting.getTooltipFormats(valueKey);

  const metaWidth = 140;
  return (
    <div>
      <div
        style={{
          display: "inline-block",
          width: metaWidth,
          //  float: "left",
          //    fontSize: "12px",
          lineHeight: "12px",
          marginTop: "-2px",
          verticalAlign: "text-top"
        }}
      >
        <span>
          {tooltipFormats.valuePrefix && tooltipFormats.valuePrefix}
          {minValue !== undefined &&
            commaNumber(DataFormatting.formatValue(valueKey, minValue))}
        </span>
        <span> : </span>
        <span style={{ whiteSpace: "nowrap" }}>
          {tooltipFormats.valuePrefix && tooltipFormats.valuePrefix}
          {maxValue !== undefined &&
            commaNumber(DataFormatting.formatValue(valueKey, maxValue))}
        </span>
      </div>
      <div style={{ display: "inline-block" }}>
        <HighchartsReact
          highcharts={Highcharts}
          constructorType={"stockChart"}
          options={{
            series: [
              {
                name: seriesName,
                data: seriesData,
                color: seriesColor,
                tooltip: {
                  ...tooltipFormats
                }
              }
            ],
            rangeSelector: {
              enabled: false
            },
            navigator: {
              enabled: false
            },
            chart: {
              backgroundColor: null,
              borderWidth: 0,
              type: "area",
              margin: [2, 0, 2, 0],
              width: width - metaWidth,
              height: 25,
              style: {
                overflow: "visible"
              },
              // zoomType: "xy",

              // small optimalization, saves 1-2 ms each sparkline
              skipClone: true
            },
            title: {
              text: ""
            },
            credits: {
              enabled: false
            },
            xAxis: {
              type: "datetime",
              labels: {
                enabled: false
              },
              title: {
                text: null
              },
              startOnTick: false,
              endOnTick: false,
              tickPositions: [],
              plotLines: midnightPlots,
              plotBands: weekendBands
            },
            yAxis: {
              endOnTick: false,
              startOnTick: false,
              labels: {
                enabled: false
              },
              title: {
                text: null
              },
              tickPositions: [0]
            },
            legend: {
              enabled: false
            },
            scrollbar: {
              enabled: false
            },
            tooltip: {
              backgroundColor: "white",
              borderWidth: 1,
              hideDelay: 0,
              shared: true,
              padding: 8,
              borderColor: "silver",
              borderRadius: 3,
              split: false
              // positioner: function(w, h, point) {
              //   return { x: point.plotX - w / 2, y: point.plotY - h };
              // }
            },
            plotOptions: {
              series: {
                animation: false,
                lineWidth: 1,
                shadow: false,
                states: {
                  hover: {
                    lineWidth: 1
                  }
                },
                marker: {
                  radius: 1,
                  states: {
                    hover: {
                      radius: 2
                    }
                  }
                },
                fillOpacity: 0.75
              }
            }
          }}
        />
      </div>
    </div>
  );
};

CellSparkline.propTypes = {
  data: PropTypes.array,
  dtKey: PropTypes.string.isRequired,
  valueKey: PropTypes.string.isRequired,
  isDarkTheme: PropTypes.bool.isRequired,
  midnightPlots: PropTypes.array.isRequired,
  weekendBands: PropTypes.array.isRequired,
  dateTimeSeries: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired
};

export default CellSparkline;
