import React, { Component } from "react";
import PropTypes from "prop-types";

import { ButtonGroupSingleSelection, ThemeTypes } from "../../_components";
// https://medium.com/@mzohaib.qc/ant-design-dynamic-runtime-theme-1f9a1a030ba0

class ThemePicker extends Component {
  state = {
    style: this.props.style,
    selectedValue: this.props.selectedValue,
    themes: {
      [ThemeTypes.Dark]: {
        "@primary-color": "#128c84",
        "@layout-body-background": "#555",
        "@text-color": "#eee",
        "@border-color-base": "#555",
        "@btn-default-bg": "#222", //"#1e4877",
        "@layout-trigger-background": "#222"
      },
      [ThemeTypes.Light]: {
        "@primary-color": "rgb(19, 168, 158)",
        "@btn-default-bg": "#ffffff"
      }
    }
  };

  onChangeTheme = value => {
    //apply theme
    //  console.log("THEME IS CHANGED TO", value);
    this.applyTheme(value);
    //update parent component and settings
    this.setState({ selectedValue: value }, () => this.props.onChange(value));
  };

  applyTheme = theme => {
    const { themes } = this.state;
    try {
      //console.log("Applying theme", JSON.stringify(themes[theme]));
      window.less
        .modifyVars(themes[theme])
        .then(() => {
          //  localStorage.setItem("app-theme", JSON.stringify(THEMES[theme]));
        })
        .catch(error => {
          console.error(`Failed to update theme`, error);
        });
    } finally {
    }
  };

  componentWillMount() {
    const { selectedValue } = this.state;
    //apply theme on load
    this.applyTheme(selectedValue);
  }

  render() {
    const { style, selectedValue } = this.state;
    return (
      <div style={style}>
        <ButtonGroupSingleSelection
          options={[
            { text: "Light theme", value: ThemeTypes.Light, icon: "sunrise" },
            { text: "Dark Theme", value: ThemeTypes.Dark, icon: "sunset" }
          ]}
          onChange={this.onChangeTheme}
          selectedValue={selectedValue}
        />
      </div>
    );
  }
}

ThemePicker.propTypes = {
  style: PropTypes.object,
  selectedValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default ThemePicker;
